export const submitApplication = async (applicationData) => {
  try {
    const response = await fetch('https://api.cloudnext.dev/apply', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(applicationData)
    });

    const data = await response.json();
    return data;
  } catch (error) {
    return {
      status: 'fail',
      message: 'Failed to submit application. Please try again later.'
    };
  }
};
