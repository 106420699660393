import { motion } from 'framer-motion';
import { useState } from 'react';
import DiscussProjectButton from './DiscussProjectButton';

const Portfolio = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const projectsPerView = window.innerWidth >= 1024 ? 3 : window.innerWidth >= 768 ? 2 : 1;
  
  const onContactClick = () => {
    document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
  };

  const projects = [
    {
      title: "Health Tracker",
      description: "Take control of your health. Track your daily activities and get valuable insights",
      tags: [
        { text: "Web App", bgClass: "bg-sky-500/10", textClass: "text-sky-300" },
        { text: "Health", bgClass: "bg-indigo-500/10", textClass: "text-indigo-300" }
      ],
      link: "https://peakk.net/",
      hoverColor: "sky",
      buttonClass: "text-sky-400 hover:text-sky-300"
    },
    {
      title: "Concept Golf",
      description: "Built around the key concepts that actually help your gold, rather than the usual noise out there.",
      tags: [
        { text: "Mobile App", bgClass: "bg-amber-500/10", textClass: "text-amber-300" },
        { text: "Sports", bgClass: "bg-orange-500/10", textClass: "text-orange-300" }
      ],
      storeLinks: {
        android: "https://play.google.com/store/apps/details?id=com.arigolf.golfcoachapp",
        ios: "https://apps.apple.com/sg/app/concept-golf/id6670228457"
      },
      hoverColor: "amber",
      buttonClass: "text-amber-400 hover:text-amber-300"
    },
    {
      title: "Ultimate Directory",
      description: "Discover Amazing Apps & Websites. AI powered listing algorithm",
      tags: [
        { text: "Web App", bgClass: "bg-indigo-500/10", textClass: "text-indigo-300" },
        { text: "AI", bgClass: "bg-purple-500/10", textClass: "text-purple-300" }
      ],
      link: "https://ultimatedirectory.net/",
      hoverColor: "indigo",
      buttonClass: "text-indigo-400 hover:text-indigo-300"
    },

    {
      title: "Brickd Keyring",
      description: "Design your own Lego keyring and get it delivered to your doorstep.",
      tags: [
        { text: "E-commerce", bgClass: "bg-emerald-500/10", textClass: "text-emerald-300" },
        { text: "Customization", bgClass: "bg-teal-500/10", textClass: "text-teal-300" }
      ],
      link: "https://brickd.peakk.net/",
      hoverColor: "emerald",
      buttonClass: "text-emerald-400 hover:text-emerald-300"
    },
    
    {
      title: "TapKat Marketplace",
      description: "The best experience for exchanging stuff in your community",
      tags: [
        { text: "Mobile App", bgClass: "bg-rose-500/10", textClass: "text-rose-300" },
        { text: "Buy & Sell", bgClass: "bg-pink-500/10", textClass: "text-pink-300" }
      ],
      link: "https://tapkat.cloudnext.dev/",
      hoverColor: "rose",
      buttonClass: "text-rose-400 hover:text-rose-300"
    }
  ];

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      (prevIndex + 1) >= (projects.length - projectsPerView + 1) ? 0 : prevIndex + 1
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? projects.length - projectsPerView : prevIndex - 1
    );
  };

  return (
    <div id="portfolio-section" className="relative py-24 bg-gradient-to-b from-slate-700 to-slate-800">
      <div className="container mx-auto px-6">
        <div className="max-w-5xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-white mb-4">Apps we have built</h2>
            <p className="text-slate-300">Recent projects that made it to production</p>
          </motion.div>
          
          <div className="relative">
            {/* Carousel Navigation Buttons */}
            <button 
              onClick={prevSlide} 
              className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-5 z-10 bg-slate-700/80 hover:bg-slate-600 text-white rounded-full p-3 shadow-lg"
              disabled={currentIndex === 0}
              style={{ opacity: currentIndex === 0 ? 0.5 : 1 }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>
            
            <button 
              onClick={nextSlide} 
              className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-5 z-10 bg-slate-700/80 hover:bg-slate-600 text-white rounded-full p-3 shadow-lg"
              style={{ opacity: 1 }} // Removed disabled state to allow continuous navigation
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>

            {/* Carousel Container */}
            <div className="overflow-hidden">
              <motion.div 
                className="flex transition-transform duration-300 ease-in-out gap-6"
                initial={{ x: 0 }}
                animate={{ x: `-${currentIndex * (100 / projectsPerView)}%` }}
                transition={{ duration: 0.1 }}
              >
                {projects.map((project, index) => (
                  <motion.div 
                    key={index}
                    className="relative bg-slate-800/80 backdrop-blur-sm rounded-2xl overflow-hidden 
                              border border-slate-700/50 hover:border-sky-500/50 hover:shadow-sky-500/10 
                              hover:shadow-2xl transition-all duration-300 group"
                    style={{
                      flex: `0 0 calc(${100 / projectsPerView}% - 20px)`,
                      // margin: '0 10px'
                    }}
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    whileHover={{ y: -5 }}
                  >
                    <div className={`absolute inset-0 bg-gradient-to-br from-${project.hoverColor}-500/10 to-transparent 
                                  opacity-0 group-hover:opacity-100 transition-opacity duration-300`}></div>
                    <div className="relative p-8">
                      <div className="absolute -left-2 -top-2 w-8 h-8 bg-sky-500/10 rounded-full blur-xl 
                                    group-hover:bg-sky-500/20 transition-colors duration-300"></div>
                      <h3 className={`font-bold text-2xl mb-3 text-white group-hover:text-${project.hoverColor}-300 
                                  transition-colors`}>{project.title}</h3>
                      <p className="text-slate-300 mb-6">{project.description}</p>
                      <div className="flex flex-wrap gap-2 mb-6">
                        {project.tags.map((tag, i) => (
                          <span key={i} className={`px-3 py-1 ${tag.bgClass} ${tag.textClass} rounded-full text-sm`}>
                            {tag.text}
                          </span>
                        ))}
                      </div>
                      <div className="pt-4 border-t border-slate-700/50">
                        {project.storeLinks ? (
                          <div className="flex flex-wrap gap-3">
                            <a 
                              href={project.storeLinks.android} 
                              className={`inline-flex items-center gap-1.5 ${project.buttonClass} transition-colors`}
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M5 16c0 3.87 3.13 7 7 7s7-3.13 7-7v-4H5v4zM16.12 4.37l2.1-2.1-.82-.83-2.3 2.31C14.16 3.28 13.12 3 12 3s-2.16.28-3.09.75L6.6 1.44l-.82.83 2.1 2.1C6.14 5.64 5 7.68 5 10v1h14v-1c0-2.32-1.14-4.36-2.88-5.63zM9 9c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1zm6 0c-.55 0-1-.45-1-1s.45-1 1-1 1 .45 1 1-.45 1-1 1z"/>
                              </svg>
                              Android
                            </a>
                            <a 
                              href={project.storeLinks.ios} 
                              className={`inline-flex items-center gap-1.5 ${project.buttonClass} transition-colors`}
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 24 24" fill="currentColor">
                                <path d="M17.05 20.28c-.98.95-2.05.8-3.08.35-1.09-.46-2.09-.48-3.24 0-1.44.62-2.2.44-3.06-.35C2.79 15.74 3.51 7.59 8.42 7.31c1.33.07 2.21.7 2.95.75.89 0 1.92-.83 3.48-.83 2.13 0 3.61 1.23 3.61 1.23.28.17.48.39.62.67-4.09 2.33-3.29 7.5.97 11.15zm-4.08-14.45c.78-.92 1.26-2.26 1.12-3.67-1.23.17-2.65 1.05-3.47 2.13-.75.93-1.29 2.3-1.12 3.6 1.31.05 2.7-.88 3.47-2.06z"/>
                              </svg>
                              iOS
                            </a>
                          </div>
                        ) : (
                          <a 
                            href={project.link} 
                            className={`inline-flex items-center gap-2 ${project.buttonClass} transition-colors`}
                            target="_blank" 
                            rel="noopener noreferrer"
                          >
                            Learn more
                            <span className="text-xs">→</span>
                          </a>
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
            
            {/* Carousel Indicators */}
            <div className="flex justify-center mt-8 space-x-2">
              {Array.from({ length: projects.length - projectsPerView + 1 }).map((_, i) => (
                <button
                  key={i}
                  onClick={() => setCurrentIndex(i)}
                  className={`h-2 rounded-full transition-all duration-300 ${
                    currentIndex === i ? 'w-8 bg-sky-400' : 'w-2 bg-slate-600'
                  }`}
                ></button>
              ))}
            </div>
          </div>
          
          <DiscussProjectButton onContactClick={onContactClick} />
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
