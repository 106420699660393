import { motion } from 'framer-motion';
import DiscussProjectButton from './DiscussProjectButton'; // Import the new component

const points = [
  {
    number: "1",
    content: (
      <p className="text-slate-300 leading-relaxed">
        <span className="font-semibold text-white">Leadership with 20+ years of experience</span> building 
        software.
      </p>
    )
  },
  {
    number: "2",
    content: (
      <p className="text-slate-300 leading-relaxed">
        <span className="font-semibold text-white">Technical and project management</span> expertise to deliver what you need, with quality.
      </p>
    )
  },
  {
    number: "3",
    content: (
      <p className="text-slate-300 leading-relaxed">
        <span className="font-semibold text-white">Proven track record</span> of building apps for highly satisfied customers.
      </p>
    )
  }
];

const WhyChooseUs = ({ onContactClick }) => {
  return (
    <div id="why-section" className="relative py-24 bg-gradient-to-b from-slate-800 to-slate-700">
      <div className="container mx-auto px-6">
        <div className="max-w-3xl mx-auto">
          {/* Background Decorative Elements */}
          <div className="absolute inset-0 overflow-hidden pointer-events-none">
            <div className="absolute top-1/4 -left-10 w-72 h-72 bg-blue-500/10 rounded-full blur-3xl"></div>
            <div className="absolute bottom-1/4 -right-10 w-72 h-72 bg-sky-500/10 rounded-full blur-3xl"></div>
          </div>

          {/* Content */}
          <div className="relative">
            <div className="flex items-center gap-4 mb-12">
              <div className="w-1.5 h-12 bg-gradient-to-b from-blue-500 to-sky-500 rounded-full"></div>
              <h2 className="text-3xl font-bold text-white">Why Choose CloudNext?</h2>
            </div>
            
            <div className="space-y-12">
              {points.map((point, index) => (
                <motion.div 
                  key={point.number}
                  className="group relative pl-16"
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                >
                  <div className="absolute left-0 top-0 w-8 h-8">
                    <div className={`absolute inset-0 bg-sky-500/20 rounded-lg rotate-45 group-hover:rotate-0 transition-transform duration-300`}></div>
                    <div className="absolute inset-0 flex items-center justify-center">
                      <span className={`text-2xl font-bold text-sky-400 group-hover:text-sky-100 transition-colors duration-300`}>{point.number}</span>
                    </div>
                  </div>
                  <div className="relative">
                    <div className={`absolute -left-8 top-1/2 h-0.5 w-6 bg-gradient-to-r from-transparent to-sky-500/50`}></div>
                    {point.content}
                  </div>
                </motion.div>
              ))}
            </div>

            <DiscussProjectButton onContactClick={onContactClick} /> {/* Add the new component here */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
