import { motion } from 'framer-motion';
import DiscussProjectButton from './DiscussProjectButton'; // Import the new component

const pricingData = [
  {
    tier: "Starter",
    price: "From $4K",
    description: "For simple MVPs and applications with basic functionality.",
    examples: ["Booking applications", "To-do applications"],
  },
  {
    tier: "Advanced",
    price: "From $15K",
    description: "For more complex apps like social and community applications.",
    examples: ["Basic Social networking", "Community forums"],
  },
  {
    tier: "Premium",
    price: "From $35K",
    description: "For highly complex applications requiring advanced features and scalability.",
    examples: ["Marketplace applications", "Stock trading platforms"],
  }
];

const Pricing = () => {
    const onContactClick = () => {
        document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
    };

  return (
    <div id="pricing-section" className="relative py-24 bg-gradient-to-b from-slate-700 to-slate-800">
      <div className="container mx-auto px-6">
        <div className="max-w-5xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-white mb-4">Pricing Guide</h2>
            <p className="text-slate-300">See typical investment ranges based on project complexity</p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            {pricingData.map((plan, index) => (
              <motion.div 
                key={plan.tier}
                className="group relative bg-slate-800/80 backdrop-blur-sm rounded-2xl p-8 shadow-xl 
                          border border-slate-700/50 hover:border-sky-500/50 hover:shadow-sky-500/10 
                          hover:shadow-2xl transition-all duration-300"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ y: -5 }}
              >
                <div className="absolute inset-0 bg-gradient-to-br from-sky-500/10 to-transparent 
                              opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                <div className="relative">
                  <div className="absolute -left-2 -top-2 w-8 h-8 bg-sky-500/10 rounded-full blur-xl 
                                group-hover:bg-sky-500/20 transition-colors duration-300"></div>
                  <div className="flex items-center justify-between mb-8">
                    <h3 className="text-2xl font-bold text-white group-hover:text-sky-300 
                                 transition-colors duration-300">{plan.tier}</h3>
                    <span className="px-3 py-1 bg-slate-700/50 text-sky-300 rounded-full text-sm">{plan.price}</span>
                  </div>
                  <div className="space-y-4">
                    <p className="text-slate-300">{plan.description}</p>
                    <div className="h-px bg-slate-700"></div>
                    <div className="space-y-2">
                      <p className="text-sm text-slate-400">Examples of apps:</p>
                      <ul className="space-y-2">
                        {plan.examples.map((example, i) => (
                          <li key={i} className="flex items-center gap-2 text-slate-300 group-hover:text-slate-200 transition-colors">
                            <svg className="w-5 h-5 text-green-500 flex-shrink-0 group-hover:text-sky-400 transition-colors" 
                                 fill="none" stroke="currentColor" viewBox="0 0 24 24">
                              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                            </svg>
                            {example}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          {/* <DiscussProjectButton onContactClick={onContactClick} /> Add the new component here */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
