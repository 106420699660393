/**
 * Submits the contact form data to the email API
 * @param {Object} formData - The form data containing name, email and message
 * @returns {Promise<Object>} The API response
 */
export const submitContactForm = async (formData) => {
  try {
    let url = 'https://send-email-bamb2enmja-ts.a.run.app/sendemail/send';
    // let url = 'http://localhost:3000/sendemail/send';
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin,
        'cloudnext-api-key': process.env.REACT_APP_CLOUDNEXT_API_KEY,
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        message: formData.message,
      }),
    });
    
    // const data = await response.json();
    const responseText = await response.text();
    console.log('Raw response:', responseText);
    
    let data;
    try {
      data = JSON.parse(responseText);
    } catch (e) {
      console.error('Failed to parse response:', e);
      return {
        status: 'error',
        message: 'Invalid response from server'
      };
    }
    
    if (data.status === 'SUCCESS') {
      return {
        status: 'success',
        message: 'Message sent successfully! We will get back to you shortly.'
      };
    } else {
      return {
        status: 'error',
        message: data.message || 'Something went wrong. Please try again later.'
      };
    }
  } catch (error) {
    console.error('Error sending contact form:', error);
    return {
      status: 'error',
      message: 'Failed to send your message. Please try again later.'
    };
  }
};
