import React, { useState } from 'react';
import { motion } from 'framer-motion';
import ApplicationModal from './components/ApplicationModal';
import ContactForm from './components/ContactForm';
import Portfolio from './components/Portfolio';
import Testimonials from './components/Testimonials';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import WhyChooseUs from './components/WhyChooseUs';
// import DiscussProjectButton from './components/DiscussProjectButton'; // Remove import

function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll listener
  React.useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 10);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  // Add floating animation configuration
  const floatingAnimation = {
    y: [-3, 3],
    x: [0,0],  // reduced range for subtler movement
    transition: {
      duration: 1.5,  // slower animation
      repeat: Infinity,
      repeatType: "reverse",
      ease: "easeInOut"
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="min-h-screen bg-slate-800 overflow-hidden relative">
      <ApplicationModal 
        isOpen={isModalOpen} 
        onClose={() => setIsModalOpen(false)} 
      />
      
      {/* Decorative Elements */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
        <div className="absolute top-20 left-10 w-64 h-64 bg-cyan-100/40 rounded-full mix-blend-overlay filter blur-2xl animate-pulse"></div>
        <div className="absolute top-40 right-10 w-72 h-72 bg-sky-100/40 rounded-full mix-blend-overlay filter blur-2xl animate-pulse delay-1000"></div>
        <div className="absolute bottom-40 left-20 w-48 h-48 bg-indigo-500/20 rounded-full mix-blend-overlay filter blur-2xl animate-pulse delay-500"></div>
        <div className="absolute top-1/2 right-1/4 w-56 h-56 bg-cyan-400/20 rounded-full mix-blend-overlay filter blur-2xl animate-pulse delay-700"></div>
        {/* <div className="absolute bottom-20 right-20 w-40 h-40 border-8 border-sky-400/20 rounded-full animate-spin-slow delay-1000"></div> */}
      </div>

      {/* Centered Logo/Text */}
      <div className="fixed w-full z-50">
        <div className={`h-20 w-full transition-colors duration-200 ${
          isScrolled 
            ? 'bg-slate-900/80 backdrop-blur-md' 
            : 'bg-transparent'
        }`}>
          <div className="container mx-auto px-6 h-full">
            <div className="flex justify-center items-center h-full">
              <div className="text-sky-300 font-bold text-xl tracking-tight relative group">
                Cloud<span className="text-blue-100">Next</span>
                <div className="absolute -bottom-2 left-0 w-0 group-hover:w-full h-0.5 bg-sky-400 transition-all duration-300"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Hero Section */}
      <div id="hero-section" className="relative pt-24 pb-20 px-6 mt-10">
        <div className="container mx-auto">
          <div className="max-w-4xl mx-auto text-center">
            <div className="space-y-4">
              <motion.img 
                src="/assets/images/rocket5.png" 
                alt="Rocket launching - CloudNext App Development"  
                className="w-[60px] h-[60px] mx-auto mb-4"
                animate={floatingAnimation}
              />
              <div className="space-y-6">
                <motion.h1 
                  className="font-bold leading-tight"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.4 }}
                >
                  <span className="block mb-2 text-5xl md:text-6xl">
                    <span className="relative inline-block">
                      <span className="relative z-10 bg-gradient-to-r from-cyan-200 via-cyan-300 to-cyan-300 text-transparent bg-clip-text font-bold">
                        We will build your app
                      </span>
                    </span>
                  </span>
                  <motion.span 
                    className="block relative text-5xl md:text-6xl"
                    initial={{ y: 20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                  >
                    <span className="relative z-10 bg-gradient-to-r from-cyan-50 via-cyan-50 to-cyan-50 text-transparent bg-clip-text font-bold">
                    Just the way you want it.
                    </span>
                    <motion.div 
                      className="absolute -z-10 w-full h-full top-0 left-0 bg-gradient-to-r from-blue-600/20 to-sky-500/20 blur-2xl"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 0.5, delay: 0.7 }}
                    ></motion.div>
                  </motion.span>
                </motion.h1>
                <motion.p 
                  className="relative flex flex-col items-center justify-center gap-1"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.8 }}
                >
                  <span className="text-xl md:text-1xl font-light mb-4">
                    <span className="relative inline-block px-2">
                      <span className="relative z-10 text-transparent bg-clip-text bg-gradient-to-r from-cyan-100 via-sky-100 to-sky-100 tracking-wide">
                      Backed by 20+ Years of Software Expertise.
                      </span>
                    </span>
                  </span>
                  <span className="text-lg max-w-3xl mx-auto text-slate-300 mt-2">
                  From MVPs to SaaS platforms to business websites, we build solutions tailored to your needs
                  </span>
                </motion.p>
                <motion.div 
                  className="flex flex-col items-center gap-4 mt-12"
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.5, delay: 1.0 }}
                >
                  <motion.button 
                    onClick={() => scrollToSection('contact-section')} // Changed this line
                    className="px-8 py-3 bg-gradient-to-r from-cyan-900 to-cyan-700 text-white rounded-lg transform hover:-translate-y-1 transition-all duration-200 shadow-lg hover:shadow-xl"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    Get started now
                  </motion.button>
                  {/* <span className="text-sm text-slate-400">2 slots available starting March 2025</span> */}
                  <motion.button 
                    onClick={() => scrollToSection('why-section')}
                    className="group flex flex-col items-center gap-1 text-slate-200 hover:text-sky-300 transition-all duration-300"
                    whileHover={{ y: -2 }}
                  >
                    <span className="relative text-lg mt-10 text-cyan-300">
                      Why choose us?
                      <span className="absolute -bottom-1 left-0 w-0 h-[1px] bg-cyan-300 transition-all duration-300 group-hover:w-full"></span>
                    </span>
                    <svg className="w-4 h-4 animate-bounce mt-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                    </svg>
                  </motion.button>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <DiscussProjectButton onContactClick={() => scrollToSection('contact-section')} /> */} {/* Remove the component */}

      <WhyChooseUs onContactClick={() => scrollToSection('contact-section')} />
      {/* <DiscussProjectButton onContactClick={() => scrollToSection('contact-section')} /> */} {/* Remove the component */}

      <Portfolio />
      {/* <DiscussProjectButton onContactClick={() => scrollToSection('contact-section')} /> */} {/* Remove the component */}

      <Testimonials />
      {/* <DiscussProjectButton onContactClick={() => scrollToSection('contact-section')} /> */} {/* Remove the component */}

      <Pricing />
      {/* <DiscussProjectButton onContactClick={() => scrollToSection('contact-section')} /> */} {/* Remove the component */}

      <Contact />

      <div className="text-center mb-6">
        <button
          onClick={scrollToTop}
          className="text-sm text-sky-400 hover:text-sky-300 transition-colors duration-200"
        >
          Back to Top ↑
        </button>
      </div>

      {/* Footer */}
      <footer className="bg-slate-900 border-t border-slate-700">
        <div className="container mx-auto px-6 py-12">              
          <div className="flex flex-col items-center">
            {/* Social Links */}
            <div className="flex space-x-6 mb-8">
              <a href="https://www.linkedin.com/company/cloud-next/" className="text-slate-400 hover:text-sky-300 transition-colors duration-2000" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">LinkedIn</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
                </svg>
              </a>
              
              <a href="https://x.com/goybs" className="text-slate-400 hover:text-sky-300 transition-colors duration-200" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">X (Twitter)</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
              </a>
              
              <a href="mailto:info@cloudnext.dev" className="text-slate-400 hover:text-sky-300 transition-colors duration-200">
                <span className="sr-only">Email</span>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                </svg>
              </a>
              
              <a href="https://www.facebook.com/cloudnext.dev" className="text-slate-400 hover:text-sky-300 transition-colors duration-200" target="_blank" rel="noopener noreferrer">
                <span className="sr-only">Facebook</span>
                <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24">
                  <path d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"/>
                </svg>
              </a>
            </div>
            
            {/* Legal Links */}
            {/* <div className="flex space-x-6 mb-6">
              <a href="#" className="text-sm text-slate-400 hover:text-sky-300 transition-colors duration-200">
                Terms of Use
              </a>
              <a href="#" className="text-sm text-slate-400 hover:text-sky-300 transition-colors duration-200">
                Privacy Policy
              </a>
            </div> */}
            
            {/* Copyright */}
            <p className="text-sm text-slate-400">
              © 2025 CloudNext. All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
