import { motion } from 'framer-motion';
import DiscussProjectButton from './DiscussProjectButton'; // Import the new component

const testimonialData = [
  {
    quote: `A pleasure to work with. Excellent communication and follow-up. The fixed price ocntract gave great confidence. 
      Flawless completion of the app within the time contraints. Highly recommended. Will definitely use again for future projects.`,
    author: "Chris Kolle",
    role: "",
    initials: "CK"
  },
  // {
  //   quote: "The team's ability to understand our business needs and translate them into a powerful fintech solution was impressive. They delivered on time and within budget.",
  //   author: "Michael Rodriguez",
  //   role: "Founder, FinanceFlow",
  //   initials: "MR"
  // },
  {
    quote: `He executes tasks professionally, meeting deadlines and delivering quality work. He has a deep knowledge of React and the functioning of Cloud projects. He is calm, efficient, and will suggest good solutions for the problems that arise.
I will hire him again as soon as the opportunity arises, and I believe that says more than simple words.`,
    author: "Kra May",
    role: "",
    initials: "AK"
  }
];

const Testimonials = () => {
  const onContactClick = () => {
    document.getElementById('contact-section').scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div id="testimonials-section" className="relative py-24 bg-gradient-to-b from-slate-800 to-slate-700">
      <div className="container mx-auto px-6">
        <div className="max-w-5xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold text-white mb-4">Client feedback</h2>
            {/* <p className="text-slate-300">Real feedback from real clients</p> */}
          </motion.div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-3xl mx-auto">
            {testimonialData.map((testimonial, index) => (
              <motion.div 
                key={index}
                className="group relative bg-slate-800/80 backdrop-blur-sm rounded-xl p-8 shadow-lg 
                         transition-all duration-300 border border-slate-700/50 hover:border-sky-500/50
                         hover:shadow-sky-500/10 hover:shadow-2xl"
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                whileHover={{ y: -5 }}
              >
                <div className="absolute -top-4 left-8 w-8 h-8 bg-gradient-to-br from-blue-500 to-sky-500 rounded-full 
                              flex items-center justify-center transform group-hover:scale-110 transition-transform duration-300">
                  <svg className="w-4 h-4 text-white" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M11.192 15.757c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L9.373 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.36 2.042-.21 3.1c.15 1.058.45 2.01.9 2.86.45.85 1.02 1.533 1.73 2.05.71.518 1.52.778 2.44.778.92 0 1.697-.258 2.33-.774.633-.516.95-1.155.95-1.916zm10.5 0c0-.88-.23-1.618-.69-2.217-.326-.412-.768-.683-1.327-.812-.55-.128-1.07-.137-1.54-.028-.16-.95.1-1.956.76-3.022.66-1.065 1.515-1.867 2.558-2.403L19.873 5c-.8.396-1.56.898-2.26 1.505-.71.607-1.34 1.305-1.9 2.094s-.98 1.68-1.25 2.69-.36 2.042-.21 3.1c.15 1.058.45 2.01.9 2.86.45.85 1.02 1.533 1.73 2.05.71.518 1.52.778 2.44.778.92 0 1.697-.258 2.33-.774.633-.516.95-1.155.95-1.916z"/>
                  </svg>
                </div>
                <div className="relative">
                  <div className="absolute -left-2 -top-2 w-8 h-8 bg-sky-500/10 rounded-full blur-xl 
                                group-hover:bg-sky-500/20 transition-colors duration-300"></div>
                  <blockquote className="text-slate-300 mb-6 mt-4 text-sm relative">
                    {testimonial.quote}
                  </blockquote>
                </div>
                <div className="flex items-center gap-4 relative">
                  <div className="w-10 h-10 bg-gradient-to-br from-slate-700 to-slate-600 rounded-full 
                                flex items-center justify-center group-hover:from-sky-900 group-hover:to-slate-700 
                                transition-colors duration-300">
                    <span className="text-slate-300 font-semibold">{testimonial.initials}</span>
                  </div>
                  <div>
                    <div className="font-semibold text-white group-hover:text-sky-300 transition-colors duration-300">
                      {testimonial.author}
                    </div>
                    <div className="text-slate-400 text-sm">{testimonial.role}</div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
          <DiscussProjectButton onContactClick={onContactClick} /> {/* Add the new component here */}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
