import React from 'react';
import { motion } from 'framer-motion';

const DiscussProjectButton = ({ onContactClick }) => {
  return (
    <motion.div 
      className="mt-12 pt-12"
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ duration: 0.5, delay: 0.6 }}
    >
      <div className="text-center text-slate-400">
        Ready to discuss your project?
        <button 
          onClick={onContactClick}
          className="ml-2 text-sky-400 hover:text-sky-300 transition-colors duration-200"
        >
          Get in touch →
        </button>
      </div>
    </motion.div>
  );
};

export default DiscussProjectButton;
