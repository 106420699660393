import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <div id="contact-section" className="relative py-24 bg-gradient-to-b from-slate-700 to-slate-800">
      <div className="container mx-auto px-6">
        <div className="max-w-2xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-white mb-4">Ready to build something amazing?</h2>
            <p className="text-slate-300">Tell us about your project</p>
          </div>

          {/* Contact Form */}
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
