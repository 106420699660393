import React, { useState } from 'react';
import { submitContactForm } from '../services/contactService';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    projectType: '',
    message: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    
    const result = await submitContactForm(formData);
    
    setSubmitStatus(result);
    setIsSubmitting(false);
    
    if (result.status === 'success') {
      setFormData({ name: '', email: '', projectType: '', message: '' });
      setTimeout(() => setSubmitStatus(null), 5000);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="bg-slate-800/50 backdrop-blur-sm rounded-2xl p-8 border border-slate-700/50 relative overflow-hidden">
      {/* <h3 className="text-xl font-semibold text-white mb-6">Send us a message</h3> */}
      
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-slate-300 mb-1">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              required
              value={formData.name}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-slate-900/50 border border-slate-700/50 
                       text-slate-200 placeholder-slate-400
                       focus:border-sky-500 focus:ring-2 focus:ring-sky-500/20 outline-none 
                       transition-colors duration-200"
              placeholder="Your name"
            />
          </div>
          
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-slate-300 mb-1">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              required
              value={formData.email}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-slate-900/50 border border-slate-700/50 
                       text-slate-200 placeholder-slate-400
                       focus:border-sky-500 focus:ring-2 focus:ring-sky-500/20 outline-none 
                       transition-colors duration-200"
              placeholder="your@email.com"
            />
          </div>

          {/* <div>
            <label htmlFor="projectType" className="block text-sm font-medium text-slate-300 mb-1">Project Type</label>
            <select
              id="projectType"
              name="projectType"
              required
              value={formData.projectType}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-slate-900/50 border border-slate-700/50 
                       text-slate-200
                       focus:border-sky-500 focus:ring-2 focus:ring-sky-500/20 outline-none 
                       transition-colors duration-200"
            >
              <option value="" className="bg-slate-800">Select project type</option>
              <option value="mvp" className="bg-slate-800">MVP Development</option>
              <option value="full" className="bg-slate-800">Full Product Development</option>
              <option value="custom" className="bg-slate-800">Custom Solution</option>
            </select>
          </div> */}
          
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-slate-300 mb-1">Message</label>
            <textarea
              id="message"
              name="message"
              rows="4"
              required
              value={formData.message}
              onChange={handleChange}
              className="w-full px-4 py-3 rounded-lg bg-slate-900/50 border border-slate-700/50 
                       text-slate-200 placeholder-slate-400
                       focus:border-sky-500 focus:ring-2 focus:ring-sky-500/20 outline-none 
                       transition-colors duration-200"
              placeholder="Tell us about your project"
            ></textarea>
          </div>
        </div>

        {submitStatus && (
          <div className={`p-3 rounded-lg ${
            submitStatus.status === 'success' 
              ? 'bg-green-900/50 text-green-300 border border-green-700/50' 
              : 'bg-red-900/50 text-red-300 border border-red-700/50'
          }`}>
            {submitStatus.message}
          </div>
        )}
        
        <button
          type="submit"
          disabled={isSubmitting}
          className="group w-full py-4 text-white rounded-xl font-semibold relative overflow-hidden transition-all duration-300
                   hover:shadow-lg hover:shadow-sky-500/20 disabled:opacity-50 disabled:cursor-not-allowed bg-gradient-to-r from-cyan-900 to-cyan-700"
        >
          <div className="absolute inset-0 bg-[length:150%_150%] bg-gradient-to-br from-blue-500 via-sky-500 to-blue-500 opacity-20 
                          group-hover:opacity-30 transition-opacity duration-500 animate-[pan_3s_linear_infinite]"></div>
          <span className="relative z-10 flex items-center justify-center">
            <span className={`transition-all duration-300`}>
              {isSubmitting ? 'Sending...' : 'Send Message'}
            </span>
            {isSubmitting ? (
              <span className="absolute right-4">
                <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
              </span>
            ) : (
              <span className="absolute right-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
              </span>
            )}
          </span>
        </button>

        <p className="text-sm text-slate-400 text-center mt-4">
          We'll get back to you within 24 hours
        </p>
      </form>
    </div>
  );
};

export default ContactForm;
